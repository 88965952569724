import React from 'react'

import '../css/skeleton.css'
import '../css/normalize.css'
import '../css/components.css'

function Footer() {

    return (
        <div className="Footer container">
            <p>More Life Gaming | {new Date().getFullYear()}</p>
        </div>
    )
}

export default Footer
